<template>
  <v-row no-gutters>
    <!--v-if="showDateFrom"-->
    <v-col
      cols="12"
      md="4"
    >
      <v-menu
        v-model="menuFrom"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="computedFromDateFormatted"
            :label="$t('lbl.from')"
            persistent-hint
            readonly
            v-bind="attrs"
            outlined
            dense
            class="mr-2"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date.from"
          no-title
          :min="new Date(minDate).toISOString().substr(0, 10)"
          locale="es"
          :show-current="false"
          @input="
            menuFrom = false
            menuTo = true
            date.from
              ? (date.to = $moment(date.from)
                .toDate()
                .toISOString()
                .substr(0, 10))
              : (date.to = null)
          "
        ></v-date-picker>
      </v-menu>
    </v-col>
    <!--v-if="showDateFrom && stopSale.from"-->
    <v-col
      cols="12"
      md="4"
    >
      <v-menu
        v-model="menuTo"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="computedToDateFormatted"
            :label="$t('lbl.to')"
            :disabled="date.from === null"
            persistent-hint
            readonly
            v-bind="attrs"
            outlined
            dense
            class="ml-2"
            v-on="on"
          ></v-text-field>
        </template>
        <!--.add(1, 'day')-->
        <v-date-picker
          v-model="date.to"
          no-title
          locale="es"
          :scrollable="true"
          :show-current="false"
          :show-adjacent-months="false"
          :min="
            date.from
              ? $moment(date.from)
                .toDate()
                .toISOString()
                .substr(0, 10)
              : ''
          "
          @input="menuTo = false"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col
      cols="12"
      md="1"
    >
      <v-tooltip
        v-if="datesStopSale.length > 1"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mx-2"
            v-bind="attrs"
            fab
            dark
            x-small
            color="error"
            v-on="on"
            @click="deleteDateStopSale(pos)"
          >
            <v-icon small>
              {{ icons.mdiTrashCan }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.delete') }}</span>
      </v-tooltip>
    </v-col>
    <v-col
      cols="12"
      md="1"
    >
      <v-tooltip
        v-if="pos === 0"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mx-2"
            fab
            dark
            x-small
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="addDateStopSale"
          >
            <v-icon small>
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.insert') }}</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiEyePlus,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    date: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
  },
  data() {
    return {
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiEyePlus,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      loading: false,
      minDate: Date.now(),
      maxDate: Date.now(),
      menuFrom: false,
      menuTo: false,
    }
  },
  computed: {
    ...mapState({
      datesStopSale: state => state.app.datesStopSale,
    }),
    computedFromDateFormatted() {
      return this.date.from ? this.$moment(this.date.from).format('D-MMM-YY') : ''
    },
    computedToDateFormatted() {
      return this.date.to ? this.$moment(this.date.to).format('D-MMM-YY') : ''
    },
  },

  created() {
    this.isLoading = true
  },

  methods: {
    ...mapMutations(['addDateStopSale', 'deleteDateStopSale']),
    // eslint-disable-next-line consistent-return
    allowedDates(val) {
      if (this.date.from === val) {
        return val
      }

      /* // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.allowedDatesFligth.length; i++) {
        if (this.allowedDatesFligth[i] === val) {
          return val
        }
      } */
    },
  },
}
</script>
